import { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm, Controller } from "react-hook-form";

// Material UI imports
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import SocialLoginButtons from "../components/SocialLoginButtons"
//import DividerWithText from "../components/DividerWithText"
import Copyright from "../components/Copyright"

import PolyglotClubLogo from "../assets/polyglot-club-logo.png"

import {
  auth,
  loginEmailPassword,
} from "../firebase/users";

import {
  signInWithCustomToken,
} from "firebase/auth";

const SignInDevPage = () => {
  // Load browser history
  const navigate = useNavigate();

  const [signInSuccessMsg, setSignInSuccessMsg] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  // Get authentication variables
  const [user, loading, error] = useAuthState(auth);

  // react-hook-form
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onSubmit = async (data) => {
    const result = await loginEmailPassword(data.email, data.password);
    if (!result) {
      setSignInSuccessMsg("Invalid Email/Password");
    }
  }

  // Reroute user to the home page if already signed in
  useEffect(() => {
    // Possibly add a loading screen
    if (loading) return;

    // If logged in, navigate home
    if (user) navigate("/home/search/lang/All");

    // Sign in if a token is passed into the url
    if (searchParams.get("token")) {
        signInWithCustomToken(auth, searchParams.get("token"))
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/home/search/lang/All");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
          });
    }

  }, [user, loading]);

  return (
    <Container component="main" maxWidth="xs">
      {/** LOGIN **/}
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/** ICON AND WELCOME MESSAGE **/}
        <img src={PolyglotClubLogo} alt="Logo" width="75" />
        <Typography component="h1" variant="h5" sx={{ mt: 1, mb: 4 }}>
          Welcome Back!
        </Typography>

        {/** SIGN IN FORM **/}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >

          <SocialLoginButtons />

          {/**<DividerWithText>Or</DividerWithText>*/}

          {/**  EMAIL SIGN IN FORM **/}
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                required
                fullWidth
                id="email"
                label="Email Address"
                type="email"
                name="email"
                autoComplete="email"
                sx={{ mt: 2, mb: 2 }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                sx={{ mb: 2 }}
              />
            )}
          />


          {/** FORM SEND SUCCESS MESSAGE **/}
          {signInSuccessMsg.length > 0 && (
            <Typography
              variant="body2"
              sx={{ mb: 1 }}
              color="red"
            >
              {signInSuccessMsg}
            </Typography>
          )}

          {/** Remember me checkbox **/}
          {/** *
  <FormControlLabel
    control={<Checkbox value="remember" color="primary" />}
    label="Remember me"
  />
*/}


          <Grid container sx={{ mb: 2 }}>
            <Grid item xs>
              <Link to="/reset-password">
                <Typography variant="body2">
                  Forgot Password?
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup">
                <Typography variant="body2">
                  Sign Up
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mb: 4 }}
            style={{ textTransform: "none" }}
          >
            Sign In
          </Button>
          <Copyright />

        </Box>
      </Box>
    </Container>
  );
}

export default SignInDevPage;
