import { Timestamp } from "firebase/firestore";

function getTimeSinceTimestamp(timestamp) {
  /**
   * This function returns a string describing how much time has passed since a timestamp
   * timestamp (firebase timestamp object): Some timestamp
   */
  const minutes_passed = Math.round((Timestamp.now() - timestamp) / 60);
  var time_since = "";
  // Seconds
  if (minutes_passed < 1) {
    time_since = "less than a minute ago";

    // Minutes
  } else if (minutes_passed >= 1 && minutes_passed < 60) {
    if (minutes_passed === 1) {
      time_since = String(minutes_passed) + " minute ago";
    } else {
      time_since = String(minutes_passed) + " minutes ago";
    }

    // Hours
  } else if (minutes_passed >= 60 && minutes_passed < 1410) {
    const hours = Math.round(minutes_passed / 60);
    if (hours === 1) {
      time_since = String(hours) + " hour ago";
    } else {
      time_since = String(hours) + " hours ago";
    }

    // Days
  } else if (minutes_passed >= 1410 && minutes_passed < 43200) {
    const days = Math.round(minutes_passed / 60 / 24);
    if (days === 1) {
      time_since = String(days) + " day ago";
    } else {
      time_since = String(days) + " days ago";
    }

    // Months
  } else if (minutes_passed >= 43200 && minutes_passed < 490000) {
    const months = Math.round(minutes_passed / 60 / 24 / 30);
    if (months === 1) {
      time_since = String(months) + " month ago";
    } else {
      time_since = String(months) + " months ago";
    }

    // Years
  } else {
    const years = Math.round(minutes_passed / 60 / 24 / 30 / 12);
    if (years === 1) {
      time_since = String(years) + " year ago";
    } else {
      time_since = String(years) + " years ago";
    }
  }

  return time_since;
}

const animalColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
]

const animalList = [
  "alligator",
  "anteater",
  "armadillo",
  "axolotl",
  "badger",
  "bat",
  "beaver",
  "buffalo",
  "camel",
  "capybara",
  "chameleon",
  "cheetah",
  "chinchilla",
  "chipmunk",
  "chupacabra",
  "cormorant",
  "coyote",
  "crow",
  "dingo",
  "dinosaur",
  "duck",
  "elephant",
  "ferret",
  "fox",
  "frog",
  "giraffe",
  "gopher",
  "grizzly",
  "hedgehog",
  "hippo",
  "hyena",
  "ibex",
  "ifrit",
  "iguana",
  "jackal",
  "kangaroo",
  "koala",
  "kraken",
  "leopard",
  "lemur",
  "liger",
  "manatee",
  "mink",
  "monkey",
  "moose",
  "narwhal",
  "orangutan",
  "otter",
  "panda",
  "penguin",
  "platypus",
  "pumpkin",
  "python",
  "quagga",
  "rabbit",
  "raccoon",
  "rhino",
  "sheep",
  "shrew",
  "skunk",
  "squirrel",
  "tiger",
  "turtle",
  "walrus",
  "wolf",
  "wolverine",
  "wombat",
];

const languageOptions = {
  All: { code: "ALL", name: "All" },
  English: { code: "US", name: "English" },
  French: { code: "FR", name: "French" },
  German: { code: "DE", name: "German" },
  Hindi: { code: "IN", name: "Hindi" },
  Italian: { code: "IT", name: "Italian" },
  Japanese: { code: "JP", name: "Japanese" },
  Portuguese: { code: "PT", name: "Portuguese" },
  Spanish: { code: "ES", name: "Spanish" },
  Swedish: { code: "SE", name: "Swedish" },
};

export { getTimeSinceTimestamp, animalColors, animalList, languageOptions };
