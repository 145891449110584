import React, { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from '@mui/material/styles'

import SignInPage from "./pages/SignInPage";
import SignInDevPage from "./pages/SignInDevPage";
import LoadingPage from "./pages/LoadingPage";

const SignUpPage = React.lazy(() => import('./pages/SignUpPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const CreatePostPage = React.lazy(() => import('./pages/CreatePostPage'));
const PostPage = React.lazy(() => import('./pages/PostPage'));
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage'));
const ContinueThreadPage = React.lazy(() => import('./pages/ContinueThreadPage'));

// 404 Error solution:
// https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually

const theme = createTheme({
  palette: {
    primary: {
      main: '#af52de',
    },
    secondary: {
      main: '#c34a36',
    },
    tertiary: {
      main: "#2EBEB1",
    }
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/" element={<SignInPage />} />
        <Route path="/signindev" element={<SignInDevPage />} />
        <Route path="/signup" element={
          <Suspense fallback={<LoadingPage />}>
            <SignUpPage />
          </Suspense>
        } />
        <Route path="/reset-password" element={
          <Suspense fallback={<LoadingPage />}>
            <ResetPasswordPage />
          </Suspense>
        } />

        <Route path="/home/search/lang/:searchLanguage" element={
          <Suspense fallback={<LoadingPage />}>
            <HomePage />
          </Suspense>
        }/>
        <Route path="/home/search/text/:searchText" element={
          <Suspense fallback={<LoadingPage />}>
            <HomePage />
          </Suspense>
        }/>
        <Route path="/home/search/lang/:searchLanguage/text/:searchText" element={
          <Suspense fallback={<LoadingPage />}>
            <HomePage />
          </Suspense>
        }/>

        <Route path="/profile/:uid/lang/:searchLanguage" element={
          <Suspense fallback={<LoadingPage />}>
            <ProfilePage />
          </Suspense>
        } />

        <Route path="/create-post/lang/:searchLanguage" element={
          <Suspense fallback={<LoadingPage />}>
            <CreatePostPage />
          </Suspense>
        } />
        <Route path="/post/:postId/lang/:searchLanguage" element={
          <Suspense fallback={<LoadingPage />}>
            <PostPage />
          </Suspense>
        } />
        <Route path="/post/:postId/lang/:searchLanguage/comment/:commentId" element={
          <Suspense fallback={<LoadingPage />}>
            <ContinueThreadPage />
          </Suspense>
        } />

      </Routes>
    </ThemeProvider>
  );
};

export default App;
