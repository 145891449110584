const firebaseConfig = {
  apiKey: "AIzaSyASxdP0-uEnY4ugbtBGgcT0pVWOfbQUdGQ",
  authDomain: "lingidy.firebaseapp.com",
  databaseURL: "https://lingidy-default-rtdb.firebaseio.com",
  projectId: "lingidy",
  storageBucket: "lingidy.appspot.com",
  messagingSenderId: "337009754366",
  appId: "1:337009754366:web:3f49a05dec57a02f2d65af",
  measurementId: "G-S0HH7G15T2"
};

export default firebaseConfig;
